<template>
  <div :id="`wrapper-container-${item_id}`" class="wrapper-section-container">
    <b-button
      :id="`btn-wrapper-${item_id}`"
      v-b-tooltip.hover.v-secondary.noninteractive="`${tooltip_text}`"
      ref="btn-toggle"
      class="btn-toggle"
      variant="outline-secondary"
      size="sm"
      @click="toggleButtons()"
      ><b-icon icon="three-dots-vertical"></b-icon>
    </b-button>
    <b-popover
      ref="popover"
      class="popover-wrapper"
      v-closable="{
        exclude: ['btn-toggle'],
        handler: 'onClose',
      }"
      triggers="click blur"
      :show.sync="is_active"
      :target="`btn-wrapper-${item_id}`"
    >
      <slot name="btn-slot"></slot>
    </b-popover>
  </div>
</template>

<script>
import Vue from "vue";
import VueClosable from "vue-closable";
Vue.use(VueClosable);
export default {
  name: "ButtonWrapper",
  props: {
    item_id: {
      type: Number,
      required: true,
    },
    tooltip_text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      is_active: false,
    };
  },
  methods: {
    toggleButtons() {
      this.is_active = !this.is_active;
      return this.is_active;
    },
    onClose() {
      this.is_active = false;
      this.$refs.popover.$emit("close");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.wrapper-section-container {
  display: block;
  height: auto;
}
.btn-toggle {
  width: 28px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #bdbdbd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 0;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
.btn-toggle:hover,
.btn-toggle:focus {
  background-color: #f7f9fa;
  color: #0056b3;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.b-icon {
  font-size: 14px;
}
.popover.b-popover >>> .popover-body {
  padding: 0.5rem !important;
}
</style>